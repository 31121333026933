import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import moment from 'moment'
import VueAxios from 'vue-axios'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import '../node_modules/handsontable/dist/handsontable.full.min.css'
import './plugins/element.js'
import 'nprogress/nprogress.css'

import notifications from '@/utils/notifications'

if (process.env.NODE_ENV === 'production') {
  // Set up error tracking for production
  Bugsnag.start({
    apiKey: 'de00eb721095ed4d7007a82c114b7909',
    plugins: [new BugsnagPluginVue()]
  })
  Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
}

Vue.config.productionTip = false

Vue.use(VueAxios, axios)

Vue.filter('dateTimeString', function (value) {
  if (!value) {
    return ''
  }
  value = value.toString()
  return moment(value).format('MMMM Do YYYY, h:mm a')
})

Object.defineProperty(Vue.prototype, '$moment', { value: moment })
Object.defineProperty(Vue.prototype, '$rfAlert', { value: notifications })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
