import { isObject } from '@/utils'

/**
 * Convert the api response into a set of human-readable strings.
 *
 * @param {Object|String} response
 * @returns {Array}
 */
function formatAPIResponse (response) {
  if (isObject(response)) {
    return Object.entries(response).map(err => {
      return `${err[0]}: ${JSON.stringify(err[1], null, 2)}\n`
    })
  }
  return response.split('\n').slice(0, 2) // trim stack trace (devel)
}

/**
 * Creates an el-collapse accordion component.
 *
 * @param {Function} h - createElement
 * @param {String} title
 * @param {String} content
 */
function createAccordion (h, title, content) {
  return h('el-collapse', {
    style: 'margin-top: 15px;'
  }, [
    h('el-collapse-item', {
      props: {
        title,
        name: 'error-message'
      }
    }, [
      h('pre', {
        style: 'color: #aaa; white-space: pre-wrap;'
      }, content)
    ])
  ])
}

/**
 * Renders a notification message as a MessageBox component.
 *
 * @param {Object} instance - vue instance
 * @param {String|Error} message - message to display or error
 * @param {Object} [details] - details to display in accordion
 * @param {Object} [trace] - stack trace to show in development
 * @returns {Promise}
 */
const notifications = {
  error: (instance, message, details = null, trace = null) => {
    const h = instance.$createElement
    let error = null

    if (message instanceof Error) {
      error = message
      message = message.toString()
    }

    let childNodes = message // by default, just show the error message

    // If details were provided, format and display them
    if (details) {
      childNodes = [
        createAccordion(h, message, formatAPIResponse(details))
      ]
    }
    const vnode = h('div', { id: 'error-notification' }, childNodes)

    if (process.env.NODE_ENV === 'development' && error) {
      vnode.children.push(
        createAccordion(h, 'Trace', error.stack)
      )
    }

    return instance.$msgbox({
      title: 'Something went wrong',
      type: 'error',
      message: vnode
    })
  }
}

export default notifications
