import Vue from 'vue'
import Router from 'vue-router'
import store, { mutations } from '../store'
import NProgress from 'nprogress'

// TODO: Remove this once array syntax for gaurds is supported
// https://github.com/vuejs/vue-router/issues/2688
import multiguard from 'vue-router-multiguard'

Vue.use(Router)

NProgress.configure({ showSpinner: false })

/**
 * Each route should have a meta.sidebarNavIndex prop
 * set which will be used to select the active sidebar
 * menu item on a hard page refresh.
 */
const SIDEBAR_INDEX = {
  companies: 'companies',
  rooofAccounts: 'rooofAccounts',
  craigslistAccounts: 'craigslistAccounts',
  alerts: 'alerts',
  invoices: 'invoices',
  craigslistFullService: 'craigslistFullService',
  internalReports: 'internalReports'
}

/**
 * Middleware functions for protecting routes.
 */
const guards = {
  /**
   * If the user is logged in (has a valid token),
   * continue on the route path, else send them back
   * to the login screen.
   *
   * If a login is required, record the attempted path
   * for redirection after successful authentication.
   */
  requireAuth: (to, from, next) => {
    const location = { name: 'LogIn', query: { redirect: to.path } }
    store.getters['auth/isAuthenticated'] ? next() : next(location)
  },
  /**
   * If the user is staff (has 'staff' oauth scope),
   * continue on the route path, else send them back
   * home.
   */
  requireStaff: (to, from, next) => {
    store.getters['auth/isStaff'] ? next() : next({ name: 'Root' })
  },
  /**
   * If the user is staff or is a CaaS Poster, continue on the
   * route path, else send them home.
   */
  requireCaasPoster: (to, from, next) => {
    store.getters['auth/isStaff'] || store.getters['auth/isCaasPoster']
      ? next() : next({ name: 'Root' })
  },
  /**
   * If the user is authenticated (has a valid token),
   * send them home, else continue on route
   * path. Used to prevent already authenticated users
   * from accessing the login component.
   */
  authRedirect: (to, from, next) => {
    store.getters['auth/isAuthenticated'] ? next({ name: 'Root' }) : next()
  }
}

/**
 * Export routes so they can be used in test suites.
 */
export const routes = [
  {
    path: '/login',
    name: 'LogIn',
    component: () => import('@/views/auth/LogIn'),
    beforeEnter: guards.authRedirect
  },
  {
    path: '/auth',
    name: 'Callback',
    component: () => import('@/views/auth/Callback')
  },
  {
    path: '/logout',
    name: 'LogOut',
    component: () => import('@/views/auth/LogOut')
  },
  {
    path: '/',
    component: () => import('@/views/layouts/DashView'),
    beforeEnter: multiguard([
      guards.requireAuth
    ]),
    children: [
      {
        path: '', // default route
        name: 'Root',
        redirect: to => {
          if (!store.getters['auth/isAuthenticated']) {
            return '/login'
          }
          if (!(store.getters['auth/isStaff'] || store.getters['auth/isCaasPoster'])) {
            return '/logout'
          }
          return store.getters['auth/isStaff'] ? 'companies' : 'craigslist-agency'
        }
      },
      {
        path: 'companies',
        component: {
          render (c) { return c('router-view') }
        },
        beforeEnter: guards.requireStaff,
        meta: {
          sidebarNavIndex: SIDEBAR_INDEX.companies,
          label: 'Companies'
        },
        children: [
          {
            path: '',
            name: 'CompanyList',
            component: () => import('@/views/posting-groups/companies/CompanyList')
          },
          {
            path: 'property-search',
            name: 'PropertySearch',
            component: () => import('@/views/posting-groups/properties/property-search/PropertySearch'),
            meta: {
              label: 'Property Search'
            }
          },
          {
            path: 'add',
            name: 'CompanyAdd',
            component: () => import('@/views/posting-groups/companies/CompanyAdd'),
            meta: {
              label: 'Add Company'
            }
          },
          {
            path: ':cid',
            component: () => import('@/views/posting-groups/companies/BaseCompany'),
            meta: {
              label: vm => {
                const company = vm.$store.getters.getCompanyById(vm.$route.params.cid)
                return company ? company.human_name : 'Unknown'
              }
            },
            children: [
              {
                path: '',
                name: 'CompanyOverview',
                component: () => import('@/views/posting-groups/companies/CompanyOverview'),
                redirect: 'properties',
                children: [
                  {
                    path: 'properties',
                    name: 'Properties',
                    component: () => import('@/views/posting-groups/companies/_components/CompanyOverviewPropertyTable'),
                    meta: {
                      label: 'Properties'
                    }
                  },
                  {
                    path: 'reporting-groups',
                    name: 'ReportingGroups',
                    component: () => import('@/views/posting-groups/groups/GroupList'),
                    meta: {
                      label: 'Reporting Groups',
                      type: 'reporting'
                    }
                  },
                  {
                    path: 'invoicing-groups',
                    name: 'InvoicingGroups',
                    component: () => import('@/views/posting-groups/groups/GroupList'),
                    meta: {
                      label: 'Invoicing Groups',
                      type: 'invoicing'
                    }
                  },
                  {
                    path: 'info',
                    name: 'CompanyInfo',
                    component: () => import('@/views/posting-groups/companies/_components/CompanyOverviewDetails'),
                    meta: {
                      label: 'Info'
                    }
                  },
                  {
                    path: 'notes',
                    name: 'CompanyNotes',
                    component: () => import('@/views/posting-groups/companies/_components/CompanyOverviewNotes'),
                    meta: {
                      label: 'Notes'
                    }
                  }
                ]
              },
              {
                path: 'property-map',
                name: 'PropertyMap',
                component: () => import('@/views/posting-groups/properties/property-map/PropertyMap'),
                meta: {
                  label: 'Property Map'
                }
              },
              {
                path: 'edit',
                name: 'CompanyEdit',
                component: () => import('@/views/posting-groups/companies/CompanyEdit'),
                meta: {
                  label: 'Edit'
                }
              },
              {
                path: 'portal-invite',
                name: 'PortalInvite',
                component: () => import('@/views/posting-groups/companies/portal-invite/PortalInvite'),
                meta: {
                  label: 'Portal Invite'
                }
              },
              {
                path: 'reporting-groups',
                name: 'ReportingGroupsBase',
                component: {
                  render (c) { return c('router-view') }
                },
                meta: {
                  label: 'Reporting Groups'
                },
                children: [
                  {
                    path: ':gid',
                    name: 'GroupStructure',
                    component: () => import('@/views/posting-groups/groups/GroupStructure'),
                    meta: {
                      label: vm => {
                        return vm.$store.state.selectedGroup.name
                      }
                    }
                  }
                ]
              },
              {
                path: 'properties/add',
                name: 'PropertyAdd',
                component: () => import('@/views/posting-groups/properties/property-form/PropertyAdd'),
                meta: {
                  label: 'Add Property'
                }
              },
              {
                path: 'properties/upload',
                name: 'PropertyBatchUpload',
                component: () => import('@/views/posting-groups/properties/batch-upload/PropertyBatchUpload'),
                meta: {
                  label: 'Property Upload'
                }
              },
              {
                path: 'properties/spreadsheet',
                name: 'PropertySpreadsheet',
                component: () => import('@/views/posting-groups/properties/spreadsheet/PropertySpreadsheet'),
                meta: {
                  label: 'Property Spreadsheet'
                }
              },
              {
                path: 'properties/:id',
                name: 'PropertyEdit',
                component: () => import('@/views/posting-groups/properties/property-form/PropertyEdit'),
                props: true,
                meta: {
                  label: vm => {
                    const selectedProperty = vm.$store.state.selectedProperty.property
                    return selectedProperty ? selectedProperty.name : ''
                  }
                }
              },
              {
                // Old property edit route.
                // Other projects may have links to this so we want to make sure that it still works.
                path: 'properties/:id/edit',
                redirect: { name: 'PropertyEdit' }
              },
              {
                path: 'properties/:id',
                name: 'PropertyBase',
                component: {
                  render (c) { return c('router-view') }
                },
                meta: {
                  label: vm => {
                    const selectedProperty = vm.$store.state.selectedProperty.property
                    return selectedProperty ? selectedProperty.name : ''
                  }
                },
                children: [
                  {
                    path: 'emailhandler-configuration',
                    name: 'PropertyEmailhandlerConfiguration',
                    component: () => import('@/views/posting-groups/properties/emailhandler-configuration/PropertyEmailhandlerConfiguration'),
                    meta: {
                      label: 'Emailhandler Configuration'
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'rooof-accounts',
        component: {
          render (c) { return c('router-view') }
        },
        beforeEnter: guards.requireStaff,
        meta: {
          sidebarNavIndex: SIDEBAR_INDEX.rooofAccounts,
          label: 'Rooof Accounts'
        },
        children: [
          {
            path: '',
            name: 'RooofAccounts',
            component: () => import('@/views/rooof-accounts/RooofAccountList')
          },
          {
            path: 'add',
            name: 'RooofAccountAdd',
            component: () => import('@/views/rooof-accounts/RooofAccountAdd'),
            meta: {
              label: 'Add Account'
            }
          },
          {
            path: ':id',
            name: 'RooofAccountEdit',
            component: () => import('@/views/rooof-accounts/RooofAccountEdit'),
            meta: {
              label: 'Edit Account'
            }
          }
        ]
      },
      {
        path: 'craigslist-accounts',
        component: {
          render (c) { return c('router-view') }
        },
        beforeEnter: guards.requireStaff,
        meta: {
          sidebarNavIndex: SIDEBAR_INDEX.craigslistAccounts,
          label: 'Craigslist Accounts'
        },
        children: [
          {
            path: '',
            name: 'CraigslistAccounts',
            component: () => import('@/views/craigslist-accounts/CraigslistAccountList')
          },
          {
            path: 'add',
            name: 'CraigslistAccountAdd',
            component: () => import('@/views/craigslist-accounts/CraigslistAccountAdd'),
            meta: {
              label: 'Add Account'
            }
          },
          {
            path: ':email',
            name: 'CraigslistAccountEdit',
            component: () => import('@/views/craigslist-accounts/CraigslistAccountEdit'),
            props: true,
            meta: {
              label: 'Edit Account'
            }
          }
        ]
      },
      {
        path: 'alerts',
        name: 'Alerts',
        component: () => import('@/views/alerts/AlertsList'),
        beforeEnter: guards.requireStaff,
        meta: {
          sidebarNavIndex: SIDEBAR_INDEX.alerts,
          label: 'Alerts'
        }
      },
      {
        path: 'invoices',
        name: 'Invoices',
        component: () => import('@/views/invoices/Invoices'),
        beforeEnter: guards.requireStaff,
        meta: {
          sidebarNavIndex: SIDEBAR_INDEX.invoices,
          label: 'Invoices'
        },
        redirect: 'invoices/invoicing-summaries',
        children: [
          {
            path: 'invoicing-summaries',
            name: 'InvoicingSummaries',
            component: () => import('@/views/invoices/InvoicingSummaries'),
            meta: {
              label: 'Invoicing Summaries'
            }
          },
          {
            path: 'invoicing-tools',
            name: 'InvoicingTools',
            component: () => import('@/views/invoices/InvoicingTools'),
            meta: {
              label: 'Invoicing Tools'
            }
          }
        ]
      },
      {
        path: 'craigslist-full-service',
        name: 'CraigslistFullService',
        component: () => import('@/views/caas/CraigslistFullService'),
        beforeEnter: guards.requireCaasPoster,
        meta: {
          sidebarNavIndex: SIDEBAR_INDEX.craigslistFullService,
          label: 'Craigslist Full Service'
        },
        redirect: 'craigslist-full-service/posting-queue',
        children: [
          {
            path: 'posting-queue',
            name: 'PostingQueue',
            component: () => import('@/views/caas/CaasQueue'),
            meta: {
              label: 'Posting Queue'
            }
          },
          {
            path: 'missed-tasks',
            name: 'MissedTasks',
            component: () => import('@/views/caas/MissedTasks'),
            meta: {
              label: 'Missed Tasks'
            }
          },
          {
            path: 'phone-verification',
            name: 'PhoneVerification',
            component: () => import('@/views/caas/PhoneVerification'),
            meta: {
              label: 'Phone Verification'
            }
          },
          {
            path: 'verification-numbers',
            name: 'VerificationNumbers',
            component: () => import('@/views/caas/VerificationNumbers'),
            beforeEnter: guards.requireStaff,
            meta: {
              label: 'Verification Numbers'
            }
          },
          {
            path: 'administration',
            name: 'FullServiceAdministration',
            component: () => import('@/views/caas/FullServiceAdministration'),
            beforeEnter: guards.requireStaff,
            meta: {
              label: 'Administration'
            }
          },
          {
            path: 'properties',
            name: 'FullServiceProperties',
            component: () => import('@/views/caas/FullServiceProperties'),
            beforeEnter: guards.requireStaff,
            meta: {
              label: 'Properties'
            }
          }
        ]
      },
      {
        path: 'craigslist-full-service',
        beforeEnter: guards.requireCaasPoster,
        component: {
          render (c) { return c('router-view') }
        },
        meta: {
          sidebarNavIndex: SIDEBAR_INDEX.craigslistFullService,
          label: 'Craigslist Full Service'
        },
        children: [
          {
            path: 'posting-queue',
            component: {
              render (c) { return c('router-view') }
            },
            meta: {
              label: 'Posting Queue'
            },
            children: [
              {
                path: ':id',
                name: 'FullServicePosts',
                component: () => import('@/views/caas/FullServicePosts'),
                meta: {
                  label: 'Full Service Posts'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'craigslist-agency',
        redirect: 'craigslist-full-service',
        children: [
          {
            path: 'posting-queue',
            redirect: { name: 'PostingQueue' },
            children: [ { path: ':id', redirect: { name: 'FullServicePosts' } }]
          },
          { path: 'phone-verification', redirect: { name: 'PhoneVerification' } },
          { path: 'verification-numbers', redirect: { name: 'VerificationNumbers' } },
          { path: 'administration', redirect: { name: 'FullServiceAdministration' } }
        ]
      },
      {
        path: 'internal-reports',
        name: 'InternalReports',
        redirect: 'internal-reports/unsent-email-reports',
        component: () => import('@/views/internal-reports/InternalReports'),
        beforeEnter: guards.requireStaff,
        meta: {
          sidebarNavIndex: SIDEBAR_INDEX.internalReports,
          label: 'Internal Reports'
        },
        children: [
          {
            path: 'unsent-email-reports',
            name: 'UnsentEmailReports',
            component: () => import('@/views/internal-reports/UnsentEmailReports'),
            meta: {
              label: 'Unsent Email Reports'
            }
          },
          {
            path: 'property-reports',
            component: () => import('@/views/internal-reports/BaseReport'),
            meta: {
              label: 'Property Reports'
            },
            children: [
              {
                path: '',
                name: 'PropertyReports',
                redirect: () => ({ name: 'OnboardingReport' })
              },
              {
                path: 'onboarding',
                name: 'OnboardingReport',
                component: () => import('@/views/internal-reports/OnboardingReport'),
                meta: {
                  label: 'Onboarding'
                }
              },
              {
                path: 'offboarding',
                name: 'OffboardingReport',
                component: () => import('@/views/internal-reports/OffboardingReport'),
                meta: {
                  label: 'Offboarding'
                }
              },
              {
                path: 'cancelled',
                name: 'CancelledReport',
                component: () => import('@/views/internal-reports/CancelledReport'),
                meta: {
                  label: 'Cancelled'
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/NotFound')
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

/**
 * Global route guards.
 */
router.beforeEach((to, from, next) => {
  store.commit(mutations.SET_IS_LOADING_ROUTE, true)
  if (to.name) {
    // If the route doesn't load in 200ms, display the progress bar
    setTimeout(() => {
      if (store.state.loadingRoute) {
        NProgress.start()
      }
    }, 200)
  }
  next()
})
router.afterEach((to, from) => {
  store.commit(mutations.SET_IS_LOADING_ROUTE, false)
  NProgress.done()
})

export default router
