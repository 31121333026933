import axios from 'axios'
import qs from 'qs'
import store from '@/store'
import { MissingTokenError, TokenExpiredError } from './errors'

const API_URL = process.env.VUE_APP_API_URL
// TODO: PORTAL-627 - remove groups owner type once craigslist groups has been deprecated
export const resourceOwnerTypes = ['companies', 'groups', 'properties']

axios.interceptors.request.use(config => {
  const token = store.getters['auth/accessToken']

  if (!token) {
    throw new MissingTokenError()
  }
  if (!store.getters['auth/isAuthenticated']) {
    throw new TokenExpiredError()
  }
  config.headers.Authorization = `Bearer ${token}`

  return config
})

/**
 * Wrapper for axios request method.
 *
 * See https://github.com/axios/axios#request-config
 *
 * @param {Object} config - axios config options
 * @param {string} [contentType=application/json] - content-type header
 * @returns {Promise}
 */
export const request = async (config, contentType = 'application/json') => {
  const options = {
    baseURL: API_URL,
    headers: {
      'Content-Type': contentType
    },
    paramsSerializer: params => {
      if (params instanceof URLSearchParams) {
        return params
      }
      return qs.stringify(params)
    },
    ...config
  }
  try {
    return await axios.request(options)
  } catch (err) {
    if (err.response && err.response.status === 401) {
      alert('Authentication error. Please log in to continue.')
      store.dispatch('auth/logout')
      return err.response
    } else {
      throw err
    }
  }
}

/**
 * Perform a GET request for the full set of paginated data.
 *
 * @param {String} url - api endpoint
 * @param {Number} [pageSize] - desired page size (25 to 500)
 * @param {Object} [params] - additional query params
 */
export const paginatedRequest = async (url, pageSize = 100, params = new URLSearchParams()) => {
  // Always send query params as URLSearchParams
  if (!(params instanceof URLSearchParams)) {
    const urlSearchParams = new URLSearchParams()

    for (const [key, value] of Object.entries(params)) {
      urlSearchParams.append(key, value)
    }

    params = urlSearchParams
  }
  params.append('page_size', pageSize)

  const config = {
    method: 'get',
    url,
    params
  }

  // Get the total object count from the first response
  const response = await request(config)
  const total = response.data.count
  let data = response.data.results

  // Create and send the remaning requests in parallel
  const numPages = Math.ceil(total / pageSize)
  const promises = []

  for (let page = 2; page <= numPages; page++) {
    const localConfig = {
      method: 'get',
      url,
      params: new URLSearchParams(config.params)
    }
    localConfig.params.set('page', page)
    promises.push(request(localConfig))
  }

  const responses = await Promise.all(promises)

  for (const response of responses) {
    data = data.concat(response.data.results)
  }

  return { data }
}
