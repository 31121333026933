// import serializers from '@/services/serializers/accounts'
import { request, paginatedRequest } from './index'
import { MissingIdError, MissingPayloadError } from './errors'

const RooofAccountAPI = {
  users: {
    list: async params => {
      const url = '/accounts/users/'
      const pageSize = 100
      const response = await paginatedRequest(url, pageSize, params)
      return response.data
    },
    listPage: async params => {
      const response = await request({
        method: 'get',
        url: '/accounts/users/',
        params
      })
      return response.data
    },
    retrieve: async (id, params) => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/accounts/users/${id}/`,
        params
      })
      return response.data
    },
    create: async data => {
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: '/accounts/users/',
        data
      })
      return response.data
    },
    update: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'put',
        url: `/accounts/users/${id}/`,
        data
      })
      return response.data
    },
    partialUpdate: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'patch',
        url: `/accounts/users/${id}/`,
        data
      })
      return response.data
    },
    delete: async id => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'delete',
        url: `/accounts/users/${id}/`
      })
      return response
    },
    me: async () => {
      const response = await request({
        method: 'get',
        url: '/accounts/users/me/'
      })
      return response.data
    },
    change_password: async data => {
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'get',
        url: '/accounts/users/change-password/',
        data
      })
      return response.data
    },
    change_user_password: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'put',
        url: `/accounts/users/${id}/change-password/`,
        data
      })
      return response.data
    }
  },
  portalInvite: {
    validateUser: async id => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/accounts/${id}/portal-invite/`
      })
      return response.data
    },
    createInvite: async data => {
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: '/accounts/portal-invite/',
        data
      })
      return response.data
    }
  },
  caasPosters: {
    list: async params => {
      const response = await request({
        method: 'get',
        url: '/accounts/caas-posters/'
      })
      return response.data
    }
  },
  permissions: {
    list: async (id) => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/accounts/users/${id}/object-permissions/`
      })
      return response.data
    },
    update: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'put',
        url: `/accounts/users/${id}/object-permissions/`,
        data
      })
      return response.data
    }
  }
}

export default RooofAccountAPI
