/**
 * General utility functions, etc.
 */
import moment from 'moment'

/**
 * Given a value, determine if it's null.
 *
 * @param {*} value
 * @returns {Boolean}
 */
export function isNull (value) {
  return value === null
}

/**
 * Given a value, determine if it's undefined.
 *
 * @param {*} value
 * @returns {Boolean}
 */
export function isUndefined (value) {
  return value === undefined
}

/**
 * Given a value, determine if it's a number.
 *
 * @param {*} value
 * @returns {Boolean}
 */
export function isNumeric (value) {
  return !isNaN(value - parseFloat(value))
}

/**
 * Given a value, determine if it's a string.
 *
 * @param {*} value
 * @returns {Boolean}
 */
export function isString (value) {
  return typeof value === 'string' || value instanceof String
}

/**
 * Given a value, determine if it's an empty string.
 *
 * @param {*} value
 * @returns {Boolean}
 */
export function isEmptyString (value) {
  return value === ''
}

/**
 * Given an object, determine if it's empty.
 *
 * @param {Object} value
 * @returns {Boolean}
 */
export function isEmptyObject (value) {
  return Object.keys(value).length === 0
}

/**
 * Given a value, determine if it's an object.
 *
 * @param {*} value
 * @param {Boolean} strict - strict object check (not array)
 * @returns {Boolean}
 */
export function isObject (value, strict = false) {
  const isObject = value === Object(value)
  return strict ? isObject && !Array.isArray(value) : isObject
}

/**
 * Given a value, determine if it's a Date.
 *
 * @param {*} value
 * @returns {Boolean}
 */
export function isDate (value) {
  return value instanceof Date
}

/**
 * Given a value, determine if it's a Boolean.
 *
 * @param {*} value
 * @returns {Boolean}
 */
export function isBoolean (value) {
  return typeof value === 'boolean'
}

/**
 * Given an object, determine if all the provided
 * keys exists as a direct property of that object.
 *
 * @param {Object} obj
 * @param {Array} keys
 * @returns {Boolean}
 */
export function keysInObject (obj, keys) {
  for (const key of keys) {
    if (!obj.hasOwnProperty(key)) {
      return false
    }
  }
  return true
}

/**
 * Filter array
 * Removes empty values and trims whitespace.
 *
 * @param {Array} array
 * @returns {(Array|null)}
 */
export function filterEmptyValues (array) {
  if (!Array.isArray(array)) {
    return null
  }
  return array.filter(el => el)
}

/**
 * Convert comma-separated string into an array.
 * Removes empty values and trims whitespace.
 *
 * @param {String} str
 * @returns {Array}
 */
export function csvStringToArray (str) {
  if (!str || typeof (str) !== 'string') {
    return []
  }
  return str.split(',').filter(el => el).map(el => el.trim())
}

/**
 * Convert a Date object into string representation.
 *
 * @param {Date} date
 * @param {String} [format] - moment format string
 * @returns {String}
 */
export function dateToString (date, format = 'YYYY-MM-DD') {
  return moment(date).format(format)
}

/**
 * Convert a (6 digit + ':' e.g. 16:00:00) 24 Hour Time string to standard AM/PM
 *
 * @param {String} time
 * @returns {String}
 */
export function convert24HourTo12Hour (time) {
  const hour24 = parseInt(time.substr(0, 2))
  let hour12 = hour24

  if (hour24 > 12) {
    hour12 = hour24 - 12
  }

  const amOrPm = (hour24 < 12 || hour24 === 24) ? ' AM' : ' PM'
  time = hour12 + time.substr(2, 3) + amOrPm

  return time
}

/**
 * Filters an object to only contain the allowed key(s).
 *
 * @param {Object} src
 * @param {Array} keys
 * @returns {Object}
 */
export function filterObjectByKey (src, keys) {
  return Object.keys(src)
    .filter(key => keys.includes(key))
    .reduce((obj, key) => ({ ...obj, [key]: src[key] }), {})
}

/**
 * Force-download a file.
 *
 * @param {String} data - file data
 * @param {String} fileName - file name
 * @param {String} ext - file extension
 * @param {String} type - file type
 */
export function downloadFile (data, fileName, ext, type = 'text/plain') {
  const a = document.createElement('a')
  a.style.display = 'none'
  document.body.appendChild(a)

  a.href = window.URL.createObjectURL(new Blob([data], { type }))
  a.setAttribute('download', `${fileName}.${ext}`)
  a.click()

  // Cleanup
  window.URL.revokeObjectURL(a.href)
  document.body.removeChild(a)
}

/**
 * Recursive function to flatten a nested object.
 *
 * Arrays of objects are serialized into arrays
 * of strings.
 *
 * Warning: may make your brain melt.
 *
 * @param {Object} obj
 * @param {String} prefix
 * @returns {Object}
 */
export function flattenObject (obj, prefix = '') {
  const reducer = (prev, element) => {
    let value = obj[element]

    if (isObject(value, true)) {
      return { ...prev, ...flattenObject(value, `${prefix}${element}.`) }
    }

    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        if (isObject(value[i])) {
          value[i] = JSON.stringify(value[i])
        }
      }
      value = value.join(', ')
    }

    return { ...prev, ...{ [`${prefix}${element}`]: value } }
  }

  return Object.keys(obj).reduce(reducer, {})
}

/**
 * Given a list of n object arrays and a unique property
 * of those objects, return a new array with only distict
 * values from all arrays.
 *
 * const arr1 = [{ key: 'one', value: 1 }, { key: 'two', value: 2 }]
 * const arr2 = [{ key: 'two', value: 2 }, { key: 'three', value: 3 }]
 *
 * const merged = getDistinctObjsByProp('value', arr1, arr2)
 * [
 *  { key: 'one', value: 1 },
 *  { key: 'two', value: 2 },
 *  { key: 'three', value: 3 }
 * ]
 *
 * @param {String} prop - unique object property
 * @param {...Array} arrays - object arrays
 * @returns {Array}
 */
export function getDistinctObjsByProp (prop, ...arrays) {
  const map = new Map()

  for (const array of arrays) {
    if (!array) {
      continue
    }
    for (const obj of array) {
      if (!map.has(obj[prop])) {
        map.set(obj[prop], obj)
      }
    }
  }

  return Array.from(map.values())
}

/**
 * Convert bytes to human readable format.
 *
 * @param {*} bytes
 * @param {Number}
 * @returns {String}
 */
export function formatBytes (bytes, decimals = 2) {
  if (bytes === 0) {
    return '0 Bytes'
  }
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

/**
 * Programatically load an external script.
 *
 * Can be called from within a Vue component.
 *
 * @param src
 * @returns {Promise}
 */
export function loadScript (src) {
  const el = document.createElement('script')
  el.type = 'text/javascript'
  el.async = true
  el.src = src

  document.head.appendChild(el)

  return new Promise((resolve, reject) => {
    el.addEventListener('load', () => resolve(el))
    el.addEventListener('error', reject)
    el.addEventListener('abort', reject)
  })
}

/**
 * Programatically load an external link.
 *
 * Can be called from within a Vue component.
 *
 * @param src
 * @returns {Promise}
 */
export function loadLink (src, rel = 'stylesheet', type = 'text/css') {
  const el = document.createElement('link')
  el.rel = rel
  el.type = type
  el.href = src

  document.head.appendChild(el)

  return new Promise((resolve, reject) => {
    el.addEventListener('load', () => resolve(el))
    el.addEventListener('error', reject)
    el.addEventListener('abort', reject)
  })
}
