/**
 * serializers.js
 *
 * Provides functions for serialization/deserialization
 * of objects between APIv2 and the admin-portal app.
 */
import store from '@/store'
import {
  isNull, isString, isEmptyString, isObject, filterEmptyValues
} from '@/utils'
import { MissingRequiredValueError } from '@/utils/errors'
import rooofSerializers from './rooof'
import TurndownService from 'turndown'
import { gfm } from 'turndown-plugin-gfm'

// https://github.com/mixmark-io/turndown
// Service to convert HTML to Markdown
const turndownService = new TurndownService({
  headingStyle: 'atx',
  codeBlockStyle: 'fenced'
}).use(gfm)

const serializers = {
  /**
   * De-serialize postengine group object so it can be used in the view.
   *
   * @param {Object} group
   * @returns {Object}
   */
  deserializePostEngineGroup (group) {
    if (!group) {
      throw new MissingRequiredValueError('group')
    }
    const groupCopy = JSON.parse(JSON.stringify(group))
    return groupCopy
  },
  /**
   * Serialize postengine group object in preparation for request payload.
   *
   * @param {Object} group
   * @returns {Object}
   */
  serializePostEngineGroup (group) {
    if (!group) {
      throw new MissingRequiredValueError('group')
    }
    const groupCopy = JSON.parse(JSON.stringify(group))

    // Remove empty values from arrays
    if (groupCopy.hasOwnProperty('children')) {
      groupCopy.children = filterEmptyValues(groupCopy.children)
    }
    if (groupCopy.hasOwnProperty('properties')) {
      groupCopy.properties = filterEmptyValues(groupCopy.properties)
    }

    return groupCopy
  },
  /**
   * De-serialize craigslist property object so it can be used in the view.
   *
   * @param {Object} property
   * @returns {Object}
   */
  deserializeCraigslistProperty (property) {
    if (!property) {
      throw new MissingRequiredValueError('property')
    }
    const propertyCopy = JSON.parse(JSON.stringify(property))

    // Convert region ids to string representation
    if (propertyCopy.major_region) {
      propertyCopy.major_region = store.state.regions.major_regions.find(r => r.id === propertyCopy.major_region).name
    }
    if (propertyCopy.sub_region) {
      propertyCopy.sub_region = store.state.regions.sub_regions.find(r => r.id === propertyCopy.sub_region).name
    }
    if (propertyCopy.sub_sub_region) {
      propertyCopy.sub_sub_region = store.state.regions.sub_sub_regions.find(r => r.id === propertyCopy.sub_sub_region).name
    }

    // Convert `extra` to a string
    if (isObject(propertyCopy.extra)) {
      propertyCopy.extra = JSON.stringify(propertyCopy.extra)
    }

    // De-serialize the nested Property object
    propertyCopy.property = rooofSerializers.deserializeProperty(propertyCopy.property)

    return propertyCopy
  },
  /**
   * Serialize craigslist property object in preparation for request payload.
   *
   * @param {Object} property
   * @returns {Object}
   */
  serializeCraigslistProperty (property) {
    if (!property) {
      throw new MissingRequiredValueError('property')
    }
    const propertyCopy = JSON.parse(JSON.stringify(property))

    // Convert region names back into ids
    if (propertyCopy.hasOwnProperty('major_region') && propertyCopy.major_region && typeof propertyCopy.major_region === 'string') {
      propertyCopy.major_region = store.state.regions.major_regions.find(r => r.name === propertyCopy.major_region).id
    }
    if (propertyCopy.hasOwnProperty('sub_region') && propertyCopy.sub_region && typeof propertyCopy.sub_region === 'string') {
      propertyCopy.sub_region = store.state.regions.sub_regions.find(r => r.name === propertyCopy.sub_region).id
    }
    if (propertyCopy.hasOwnProperty('sub_sub_region') && propertyCopy.sub_sub_region && typeof propertyCopy.sub_sub_region === 'string') {
      propertyCopy.sub_sub_region = store.state.regions.sub_sub_regions.find(r => r.name === propertyCopy.sub_sub_region).id
    }

    // Remove empty values from arrays
    if (propertyCopy.hasOwnProperty('location')) {
      propertyCopy.location = filterEmptyValues(propertyCopy.location)
    }
    if (propertyCopy.hasOwnProperty('title')) {
      propertyCopy.title = filterEmptyValues(propertyCopy.title)
    }
    if (propertyCopy.hasOwnProperty('romance_text')) {
      propertyCopy.romance_text = filterEmptyValues(propertyCopy.romance_text)
    }
    if (propertyCopy.hasOwnProperty('amenities_property')) {
      propertyCopy.amenities_property = filterEmptyValues(propertyCopy.amenities_property)
    }
    if (propertyCopy.hasOwnProperty('amenities_unit')) {
      propertyCopy.amenities_unit = filterEmptyValues(propertyCopy.amenities_unit)
    }
    if (propertyCopy.hasOwnProperty('header')) {
      propertyCopy.header = filterEmptyValues(propertyCopy.header)
    }
    if (propertyCopy.hasOwnProperty('footer')) {
      propertyCopy.footer = filterEmptyValues(propertyCopy.footer)
    }
    if (propertyCopy.hasOwnProperty('disclaimer')) {
      propertyCopy.disclaimer = filterEmptyValues(propertyCopy.disclaimer)
    }

    // Convert `extra` back into JSON
    if (propertyCopy.hasOwnProperty('extra') && isString(propertyCopy.extra)) {
      if (isEmptyString(propertyCopy.extra)) {
        propertyCopy.extra = {}
      } else {
        try {
          propertyCopy.extra = JSON.parse(propertyCopy.extra)
        } catch (err) {
          throw new Error('serializeCraigslistProperty received invalid JSON data for property `extra`')
        }
      }
    }

    // Serialize the nested Property object
    if (isObject(propertyCopy.property)) {
      propertyCopy.property = rooofSerializers.serializeProperty(propertyCopy.property)
    }

    return propertyCopy
  },
  /**
   * Serialize notes in preparation for request payload.
   *
   * @param {Object} notes
   * @returns {Object}
   */
  serializeNotes (notes) {
    if (!notes) {
      throw new MissingRequiredValueError('notes')
    }
    const propertyCopy = JSON.parse(JSON.stringify(notes))
    // Convert HTML to markdown
    if (propertyCopy.property_service_notes) {
      propertyCopy.property_service_notes = turndownService.turndown(propertyCopy.property_service_notes)
    }
    if (propertyCopy.company_service_notes) {
      propertyCopy.company_service_notes = turndownService.turndown(propertyCopy.company_service_notes)
    }

    return propertyCopy
  },
  /**
   * De-serialize craigslist account object so it can be used in the view.
   *
   * @param {Object} account
   * @returns {Object}
   */
  deserializeCraigslistAccount (account) {
    if (!account) {
      throw new MissingRequiredValueError('account')
    }
    const accountCopy = JSON.parse(JSON.stringify(account))

    // Store a copy of the email in case it gets updated
    accountCopy.original_email = accountCopy.email

    // Convert null values to string representation
    if (isNull(accountCopy.password)) {
      accountCopy.password = ''
    }
    if (isNull(accountCopy.proxy_ip)) {
      accountCopy.proxy_ip = ''
    }
    if (isNull(accountCopy.proxy_port)) {
      accountCopy.proxy_port = ''
    }

    return accountCopy
  },
  /**
   * Serialize craigslist account object in preparation for request payload.
   *
   * @param {Object} account
   * @returns {Object}
   */
  serializeCraigslistAccount (account) {
    if (!account) {
      throw new MissingRequiredValueError('account')
    }
    const accountCopy = JSON.parse(JSON.stringify(account))

    // Convert empty strings back into null values
    if (isEmptyString(accountCopy.proxy_ip)) {
      accountCopy.proxy_ip = null
    }
    if (isEmptyString(accountCopy.proxy_port)) {
      accountCopy.proxy_port = null
    }
    if (isEmptyString(accountCopy.region)) {
      accountCopy.region = null
    }

    // Only staff are allowed to change groups/properties
    if (!store.getters['auth/isStaff']) {
      delete accountCopy.properties
    }

    return accountCopy
  },
  /**
   * De-serialize the mapping list response, transforms
   * key-value pairs into a proper array. Can be removed
   * once API-556 is resolved.
   *
   * @param {Object} data
   */
  deserializeMappingList (data) {
    if (!data) {
      throw new MissingRequiredValueError('data')
    }
    if (Array.isArray(data)) {
      return data
    }
    const list = []
    for (const [key, value] of Object.entries(data)) {
      list.push({
        name: key,
        url: value
      })
    }
    return list
  },

  /**
   * De-serialize syndication photo data so it can be used in the view.
   *
   * @param {Object} photo
   * @returns {Object}
   */
  deserializeSyndicationPhoto (photo) {
    return {
      id: photo.id,
      url: photo.file,
      name: photo.file.split('/').slice(-1)[0]
    }
  },

  /**
   * Serialize Feature Invite object in preparation for request payload.
   *
   * @param {Object} invite
   * @returns {Object}
   */
  serializeFeatureInvite (invite) {
    if (!invite) {
      throw new MissingRequiredValueError('invite')
    }
    const inviteCopy = JSON.parse(JSON.stringify(invite))

    if (inviteCopy.instructions === '') {
      delete inviteCopy.instructions
    }

    if (inviteCopy.cc_recipients === '') {
      delete inviteCopy.cc_recipients
    }

    return inviteCopy
  }
}

export default serializers
