import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import RooofAPI from '@/services/api/rooof'
import CraigslistAPI from '@/services/api/craigslist'

import authModule from './modules/auth'

Vue.use(Vuex)

export const mutations = {
  RESET_STATE: 'RESET_STATE',
  SET: 'SET',
  CLEAR: 'CLEAR',
  SET_COMPANY_LIST: 'SET_COMPANY_LIST',
  CLEAR_COMPANY_LIST: 'CLEAR_COMPANY_LIST',
  SET_CRAIGSLIST_REGIONS: 'SET_CRAIGSLIST_REGIONS',
  CLEAR_CRAIGSLIST_REGIONS: 'CLEAR_CRAIGSLIST_REGIONS',
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  SET_IS_LOADING_COMPANIES: 'SET_IS_LOADING_COMPANIES',
  SET_PRODUCT_LIST: 'SET_PRODUCT_LIST',
  SET_FEATURE_LIST: 'SET_FEATURE_LIST',
  SET_IS_LOADING_ROUTE: 'SET_IS_LOADING_ROUTE'
}

/**
 * After making modifications to any vuex state, remove the
 * 'vuex' item from localStorage then restart the server.
 * Failure to do so can result in old values being
 * re-added by the vuex-persistedstate cache.
 */
const initialState = () => {
  return {
    loadingCompanies: false,
    loadingRoute: false,
    companies: {
      data: [],
      last_modified: null
    },
    regions: {
      major_regions: [],
      sub_regions: [],
      sub_sub_regions: []
    },
    products: [],
    features: [],
    sidebarOpen: false,
    selectedGroup: {},
    selectedProperty: {}
  }
}

/**
 * This plugin is used to cache the vuex state in localStorage
 * so that it will persist across hard page refreshes.
 */
const persistedStatePlugin = createPersistedState({
  key: 'vuex',
  storage: window.localStorage
})

/* eslint-disable no-async-promise-executor */
export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
    persistedStatePlugin
  ],
  modules: {
    auth: authModule
  },
  state: initialState,
  getters: {
    companyList: state => {
      return state.companies.data
    },
    getCompanyByName: state => name => {
      return state.companies.data.find(company => company.human_name === name)
    },
    getCompanyById: state => cid => {
      return state.companies.data.find(company => company.id === parseInt(cid, 10))
    },
    nestedRegions: state => {
      // TODO: Replace with call to API (API-231) once complete
      const majorRegions = JSON.parse(JSON.stringify(state.regions.major_regions))
      const subRegions = JSON.parse(JSON.stringify(state.regions.sub_regions))
      const subSubRegions = JSON.parse(JSON.stringify(state.regions.sub_sub_regions))

      subRegions.forEach(subRegion => {
        subRegion.subSubRegions = []

        subSubRegions.forEach(subSubRegion => {
          if (subSubRegion.sub_region_id === subRegion.id) {
            subRegion.subSubRegions.push(subSubRegion)
          }
        })

        if (subRegion.subSubRegions.length) {
          subRegion.subSubRegions = subRegion.subSubRegions.sort((a, b) => a.name.localeCompare(b.name))
        }
      })

      majorRegions.forEach(majorRegion => {
        majorRegion.subRegions = []

        subRegions.forEach(subRegion => {
          if (subRegion.major_region_id === majorRegion.id) {
            majorRegion.subRegions.push(subRegion)
          }
        })

        if (majorRegion.subRegions.length) {
          majorRegion.subRegions = majorRegion.subRegions.sort((a, b) => a.name.localeCompare(b.name))
        }
      })

      return majorRegions.sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  actions: {
    clear_company_list ({ commit }) {
      commit(mutations.CLEAR_COMPANY_LIST)
    },
    fetch_company_list ({ commit }, params = {}) {
      return new Promise(async (resolve, reject) => {
        try {
          commit(mutations.SET_IS_LOADING_COMPANIES, true)
          const companies = await RooofAPI.companies.summary(params)
          if (companies.length) {
            commit(mutations.SET_COMPANY_LIST, companies)
          }
          resolve()
        } catch (err) {
          reject(err)
        } finally {
          commit(mutations.SET_IS_LOADING_COMPANIES, false)
        }
      })
    },
    fetch_craigslist_regions ({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const regions = await CraigslistAPI.regions.list()
          commit(mutations.SET_CRAIGSLIST_REGIONS, regions.data)
          resolve()
        } catch (err) {
          reject(err)
        }
      })
    },
    fetch_product_list ({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const products = await RooofAPI.products.list()
          products.sort((a, b) => a.name.localeCompare(b.name))
          commit(mutations.SET_PRODUCT_LIST, products)
          resolve()
        } catch (err) {
          reject(err)
        }
      })
    },
    fetch_feature_list ({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const features = await RooofAPI.features.list()
          features.sort((a, b) => a.name.localeCompare(b.name))
          commit(mutations.SET_FEATURE_LIST, features)
          resolve()
        } catch (err) {
          reject(err)
        }
      })
    },
    toggle_sidebar ({ commit }) {
      commit(mutations.TOGGLE_SIDEBAR)
    }
  },
  mutations: {
    [mutations.RESET_STATE] (state) {
      Object.assign(state, initialState())
    },
    [mutations.SET] (state, [variable, value]) {
      state[variable] = value
    },
    [mutations.CLEAR] (state, variable) {
      const original = initialState()
      state[variable] = original[variable]
    },
    [mutations.SET_COMPANY_LIST] (state, companies) {
      state.companies = {
        data: companies,
        last_modified: Date.now()
      }
    },
    [mutations.CLEAR_COMPANY_LIST] (state) {
      state.companies = {
        data: [],
        last_modified: null
      }
    },
    [mutations.SET_CRAIGSLIST_REGIONS] (state, regions) {
      state.regions = regions
    },
    [mutations.CLEAR_CRAIGSLIST_REGIONS] (state) {
      state.regions = {
        major_regions: [],
        sub_regions: [],
        sub_sub_regions: []
      }
    },
    [mutations.TOGGLE_SIDEBAR] (state) {
      state.sidebarOpen = !state.sidebarOpen
    },
    [mutations.SET_IS_LOADING_COMPANIES] (state, loading) {
      state.loadingCompanies = loading
    },
    [mutations.SET_PRODUCT_LIST] (state, products) {
      state.products = products
    },
    [mutations.SET_FEATURE_LIST] (state, features) {
      state.features = features
    },
    [mutations.SET_IS_LOADING_ROUTE] (state, loading) {
      state.loadingRoute = loading
    }
  }
})
