<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@import url('https://use.typekit.net/zrx6etk.css'); /* Proxima Nova */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap'); /* Nunito Sans */

html {
  height: 100%;
}
body {
  font-family: 'proxima-nova', 'Nunito Sans', 'Segoe UI', Arial, sans-serif;
  color: #34495e;
  background-color: #fff;
  font-size: 16px;
  min-height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app {
  min-height: 100vh;
}
</style>
