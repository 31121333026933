import router from '@/router'
import AuthClient from '@/services/auth'
import RooofAccountAPI from '@/services/api/accounts'

const mutations = {
  RESET_STATE: 'RESET_STATE',
  SET_AUTH_SESSION: 'SET_AUTH_SESSION',
  CLEAR_AUTH_SESSION: 'CLEAR_AUTH_SESSION',
  SET_OAUTH_STATE: 'SET_STATE',
  CLEAR_OAUTH_STATE: 'CLEAR_STATE',
  SET_REDIRECT: 'SET_REDIRECT',
  SET_ACCOUNT: 'SET_ACCOUNT'
}

const initialState = () => {
  return {
    accessToken: '',
    expiresAt: null,
    scope: '',
    oauthState: '',
    redirect: '',
    account: null
  }
}

/**
 * Vuex authentication module.
 *
 * Contains all authentication-related state and actions.
 *
 * Basic flow:
 *  1. Component dispatches 'login' action
 *  2. Existing state is cleared
 *  3. Store sets an internal oauth state value
 *  4. User is redirected to OAuth server for authentication
 *  5. User authenticates
 *  6. OAuth server redirects back to redirect_uri with token (or error)
 *  7. Callback component dispatches 'createAuthSession'
 *  8. Response URI is parsed for token or error message
 *  8a. If successful, token is stored in state
 *  8b. If not successful, action returns rejected promise so it can be handled by callback component
 */
const authModule = {
  namespaced: true,
  state: initialState,
  getters: {
    accessToken (state) {
      return state.accessToken
    },
    expiresAt (state) {
      return state.expiresAt
    },
    isAuthenticated (state) {
      return state.expiresAt && new Date().getTime() < state.expiresAt
    },
    isStaff (state) {
      return state.scope && state.scope.indexOf('staff') !== -1
    },
    isCaasPoster (state) {
      return state.account.is_poster
    }
  },
  actions: {
    login ({ dispatch, commit, state }) {
      dispatch('resetGlobalState')
      dispatch('setOauthState')

      // Store the redirect path, if exists
      const redirectPath = router.currentRoute.query.redirect
      if (redirectPath) {
        commit(mutations.SET_REDIRECT, redirectPath)
      }

      // Redirect user to oauth login page
      AuthClient.login(state.oauthState)
    },
    logout ({ commit }) {
      commit(mutations.CLEAR_AUTH_SESSION)
      AuthClient.logout()
    },
    setOauthState ({ commit }) {
      const state = AuthClient.randomString(32)
      commit(mutations.SET_OAUTH_STATE, state)
    },
    createAuthSession ({ commit, state }) {
      return new Promise((resolve, reject) => {
        try {
          const token = AuthClient.parseResponseURI(state.oauthState)
          commit(mutations.SET_AUTH_SESSION, token)
          resolve()
        } catch (err) {
          reject(err)
        }
      })
    },
    resetGlobalState ({ commit }) {
      commit(mutations.RESET_STATE)
      commit(mutations.RESET_STATE, null, { root: true })
    },
    async fetchAccount ({ commit }) {
      const response = await RooofAccountAPI.users.me()
      commit(mutations.SET_ACCOUNT, response)
    }
  },
  mutations: {
    [mutations.RESET_STATE] (state) {
      Object.assign(state, initialState())
    },
    [mutations.SET_AUTH_SESSION] (state, token) {
      state.accessToken = token.access_token
      state.expiresAt = token.expires_at
      state.scope = token.scope
    },
    [mutations.CLEAR_AUTH_SESSION] (state) {
      state.accessToken = ''
      state.expiresAt = null
      state.scope = ''
      state.oauthState = ''
      state.redirect = ''
    },
    [mutations.SET_OAUTH_STATE] (state, value) {
      state.oauthState = value
    },
    [mutations.CLEAR_OAUTH_STATE] (state) {
      state.oauthState = ''
    },
    [mutations.SET_REDIRECT] (state, path) {
      state.redirect = path
    },
    [mutations.SET_ACCOUNT] (state, value) {
      state.account = value
    }
  }
}

export default authModule
