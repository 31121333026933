import { ExtendableError } from '@/utils/errors'

export class MissingTokenError extends ExtendableError {
  constructor (message = 'Unable to complete request: access token is missing') {
    super(message)

    // FIXME: Temporary workaround for https://babeljs.io/docs/en/caveats/#classes
    this.constructor = MissingTokenError
    /* eslint-disable no-proto */
    this.__proto__ = MissingTokenError.prototype
  }
}
export class TokenExpiredError extends ExtendableError {
  constructor (message = 'Unable to complete request: access token is expired') {
    super(message)

    // FIXME: Temporary workaround for https://babeljs.io/docs/en/caveats/#classes
    this.constructor = TokenExpiredError
    /* eslint-disable no-proto */
    this.__proto__ = TokenExpiredError.prototype
  }
}
export class MissingIdError extends ExtendableError {
  constructor (message = 'Unable to complete request: missing resource identifier') {
    super(message)

    // FIXME: Temporary workaround for https://babeljs.io/docs/en/caveats/#classes
    this.constructor = MissingIdError
    /* eslint-disable no-proto */
    this.__proto__ = MissingIdError.prototype
  }
}
export class MissingPayloadError extends ExtendableError {
  constructor (message = 'Unable to complete request: payload is required') {
    super(message)

    // FIXME: Temporary workaround for https://babeljs.io/docs/en/caveats/#classes
    this.constructor = MissingPayloadError
    /* eslint-disable no-proto */
    this.__proto__ = MissingPayloadError.prototype
  }
}

export class InvalidOwnerTypeError extends ExtendableError {
  constructor (message = 'Unable to complete request: ownerType must be "groups" or "properties"') {
    super(message)

    // FIXME: Temporary workaround for https://babeljs.io/docs/en/caveats/#classes
    this.constructor = InvalidOwnerTypeError
    /* eslint-disable no-proto */
    this.__proto__ = InvalidOwnerTypeError.prototype
  }
}
