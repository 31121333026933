/**
 * Rooof-specific utility functions.
 */
import moment from 'moment'
import { nanoid } from 'nanoid'

import { constants } from '@/utils/constants'
import craigslistSerializers from '@/services/serializers/craigslist'
import rooofSerializers from '@/services/serializers/rooof'

/**
 * Constructs a blank address object.
 *
 * @returns {Object}
 */
export function createBlankAddress () {
  return {
    street: '',
    city: '',
    state: '',
    country: '',
    postal: '',
    latitude: '',
    longitude: ''
  }
}

/**
 * Given a valid address object, determine if it's empty.
 *
 * @param {Object} address
 * @returns {Boolean}
 */
export function isEmptyAddress (address) {
  return Object.values(address).every(item => !item)
}

/**
 * Given a non-empty address object, determine if it's
 * valid. Field length restrictions are validated by
 * the handsontable cell validators.
 *
 * @param {Object} address
 * @returns {Boolean}
 */
export function isValidAddress (address) {
  const required = ['street', 'city', 'state', 'country', 'postal']
  return required.every(key => address[key])
}

/**
 * Given two address objects, determine if they contain
 * the same values for each key.
 *
 * If the address objects are both empty, returns false.
 *
 * @param {Object} a1
 * @param {Object} a2
 * @returns {Boolean}
 */
export function isDuplicateAddress (a1, a2) {
  if (isEmptyAddress(a1) && isEmptyAddress(a2)) {
    return false
  }
  return (
    a1.street === a2.street &&
    a1.city === a2.city &&
    a1.state === a2.state &&
    a1.country === a2.country &&
    a1.postal === a2.postal &&
    a1.latitude === a2.latitude &&
    a1.longitude === a2.longitude
  )
}

/**
 * Given a valid address object, format
 * it as a human-readable string.
 *
 * @param {Object} address
 * @param {Array} [fields] - fields to include in the response
 * @returns {String}
 */
export function formatAddress (address, fields = ['street', 'city', 'state', 'country', 'postal']) {
  if (!address) {
    return ''
  }
  return Object.keys(address)
    .filter(key => fields.includes(key)) // remove unwanted fields
    .filter(key => address[key]) // remove empty values
    .map(key => address[key])
    .join(', ')
}

/**
 * Constructs a blank contact object for
 * use with form elements.
 *
 * @returns {Object}
 */
export function createBlankContact () {
  return {
    id: null,
    name: '',
    email: ''
  }
}

/**
 * Constructs a blank Property object for
 * use with form elements (does not include
 * non-editable fields).
 *
 * @param {Boolean} shouldDeserialize - deserialize the raw property object
 * @returns {Object}
 */
export function createBlankProperty (shouldDeserialize = true) {
  const property = {
    name: '',
    legal_name: '',
    website_url: '',
    floor_plan_urls: [],
    all_available_units_url: '',
    type: '',
    unit_count: null,
    product_subscriptions: [],
    feature_subscriptions: [],
    features_list: [],
    config_override: {},
    crm_id: '',
    post_update_id: '',
    zoho_customer_id: '',
    billing_email: '',
    email_delivery_lead: '',
    email_delivery_lead_copy: '',
    email_delivery_property: '',
    email_agency: '',
    email_agency_decisionmaker: [],
    email_contact: '',
    email_leasing: '',
    phone_contact: '',
    phone_leasing: '',
    phone_callrail: '',
    notes: '',
    extra: {},
    property_address: createBlankAddress(),
    billing_address: createBlankAddress(),
    postengine_groups: [],
    invoicing_group: null
  }
  return shouldDeserialize
    ? rooofSerializers.deserializeProperty(property)
    : property
}

/**
 * Constructs a blank CraigslistProperty object
 * for use with form elements (does not include
 * non-editable fields).
 *
 * @param {Boolean} shouldDeserialize - deserialize the raw property object
 * @returns {Object}
 */
export function createBlankCraigslistProperty (shouldDeserialize = true) {
  const craigslistProperty = {
    property: createBlankProperty(false),
    mappings: [],
    craigslist_accounts: [],
    posting_category: '',
    email_policy: '',
    dog_policy: null,
    cat_policy: null,
    smoking_policy: null,
    wheelchair_access: null,
    furnished: null,
    laundry: '',
    parking: '',
    rent_period: '',
    location: [],
    amenities_property: [],
    amenities_unit: [],
    title_setting: 'no_titles',
    title: [],
    romance_text: [],
    header: [],
    footer: [],
    disclaimer: [],
    extra: {},
    major_region: null,
    sub_region: null,
    sub_sub_region: null,
    agreements: {
      added: [],
      removed: []
    }
  }
  return shouldDeserialize
    ? craigslistSerializers.deserializeCraigslistProperty(craigslistProperty)
    : craigslistProperty
}

/**
 * Constructs a blank Company object for
 * use with form elements (does not include
 * non-editable fields).
 *
 * @param {Boolean} shouldDeserialize - deserialize the raw company object
 * @returns {Object}
 */
export function createBlankCompany (shouldDeserialize = true) {
  const company = {
    name: '',
    human_name: '',
    account_manager: null,
    salesperson: null,
    decision_makers: [],
    emergency_contacts: [],
    category: undefined,
    email_domains: [],
    managed_properties: null,
    daily_posting_target: 3,
    lead_source: undefined,
    start_date: null,
    end_date: null,
    reason_for_cancel: undefined,
    agreement: null,
    agreement_type: undefined,
    billing_frequency: null,
    default_billing_method: undefined,
    default_rate: null,
    default_setup_fee: null,
    currency_code: undefined,
    billing_notes: '',
    active_campaign_deals: [],
    short_url_domain: '',
    short_url_params: '',
    emailhandler_lead_source: '',
    post_update_hour: 12,
    jira_mapping_url: '',
    notes: '',
    extra: {},
    mappings: [],
    craigslist_accounts: [],
    policy: '',
    disclaimer: []
  }
  return shouldDeserialize
    ? rooofSerializers.deserializeCompany(company)
    : company
}

/**
 * Constructs a blank Subscription object.
 *
 * @returns {Object}
 */
export function createBlankSubscription () {
  return {
    rate: null,
    setup_fee: null,
    discount: null,
    discount_type: '$',
    discount_expires: null,
    low_density: false,
    billing_frequency: 1,
    trial_start_date: null,
    trial_end_date: null,
    start_date: null,
    end_date: null,
    reason_for_cancel: '',
    reason_for_cancel_other: '',
    notes: '',
    uid: nanoid() // client use only
  }
}

/**
 * Constructs a blank ProductSubscription object.
 *
 * @returns {Object}
 */
export function createBlankProductSubscription () {
  return {
    ...createBlankSubscription(),
    product: null
  }
}

/**
 * Constructs a blank FeatureSubscription object.
 *
 * @returns {Object}
 */
export function createBlankFeatureSubscription () {
  return {
    ...createBlankSubscription(),
    feature: null
  }
}

/**
 * Given a Company.human_name, generate the name.
 *
 * @param {String} humanName
 * @returns {String}
 */
export function getNameFromHumanName (humanName) {
  humanName = humanName.trim()
  humanName = humanName.replace(/[.()']/g, '')
  humanName = humanName.replace(/ +/g, '-')

  return humanName.toLowerCase()
}

/**
 * Given trial dates, calculate status.
 *
 * @param {Object} status
 * @param {String|Date} trialStart
 * @param {String|Date} trialEnd
 * @returns {String}
 */
export function getTrialStatus (status, today, trialStart, trialEnd) {
  if (!trialStart && !trialEnd) {
    return null
  } else if (trialStart && !trialEnd) {
    if (trialStart <= today) {
      return status.trial
    } else if (trialStart > today) {
      return status['pre-trial']
    }
  } else if (trialStart && trialEnd) {
    if (trialEnd < today) {
      return status.inactive
    } else if (trialStart > today) {
      return status['pre-trial']
    } else if (trialEnd >= today) {
      return status.trial
    }
  }
  return status.invalid
}

/**
 * Given non-trial dates, calculate status.
 *
 * @param {Object} status
 * @param {String|Date} start
 * @param {String|Date} end
 * @returns {String}
 */
export function getNonTrialStatus (status, today, start, end) {
  if (!start && !end) {
    return null
  } else if (start && !end) {
    if (start <= today) {
      return status.active
    } else if (start > today) {
      return status.onboarding
    }
  } else if (start && end) {
    if (end < today) {
      return status.inactive
    } else if (end >= today) {
      return status.offboarding
    }
  }
  return status.invalid
}

/**
 * Given trial and non-trial statuses, calculate the status of subscription
 *
 *
 * @param {String|Date} trialStart
 * @param {String|Date} trialEnd
 * @param {String|Date} start
 * @param {String|Date} end
 * @returns {String}
 */
export function mergeStatus (status, trialStatus, nonTrialStatus) {
  if (trialStatus === status.trial || trialStatus === status['pre-trial']) {
    return trialStatus
  }
  return nonTrialStatus || trialStatus || status.invalid
}

/**
 * Given billing dates, calculate the status.
 *
 * Follows the same algorithm the api uses.
 *
 * @param {String|Date} trialStart
 * @param {String|Date} trialEnd
 * @param {String|Date} start
 * @param {String|Date} end
 * @returns {String}
 */
export function getStatus (trialStart, trialEnd, start, end) {
  trialStart = trialStart ? moment(trialStart) : null
  trialEnd = trialEnd ? moment(trialEnd) : null
  start = start ? moment(start) : null
  end = end ? moment(end) : null

  const today = moment().startOf('day')
  const status = constants.status.reduce((obj, option) => {
    obj[option.value] = option.value
    return obj
  }, {})

  if (!(trialStart || trialEnd || start || end)) {
    return status.pending
  }
  const trialStatus = getTrialStatus(status, today, trialStart, trialEnd)
  const nonTrialStatus = getNonTrialStatus(status, today, start, end)

  return mergeStatus(status, trialStatus, nonTrialStatus)
}

/**
 * Get the tag type (color) for the given status.
 * See https://element.eleme.io/#/en-US/component/tag#tag
 *
 * @param {String} status
*/
export function getStatusTagType (status) {
  switch (status) {
    case 'active':
      return 'success'
    case 'inactive':
      return 'danger'
    case 'offboarding':
      return 'warning'
    case 'onboarding':
      return 'success'
    case 'pre-trial':
      return 'primary'
    case 'auto-created':
      return 'info'
    default:
      return ''
  }
}
