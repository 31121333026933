import serializers from '@/services/serializers/craigslist'
import { request, paginatedRequest, resourceOwnerTypes } from './index'
import { MissingIdError, MissingPayloadError, InvalidOwnerTypeError } from './errors'

const CraigslistAPI = {
  groups: {
    list: async (params = {}) => {
      const response = await request({
        method: 'get',
        url: '/craigslist/postengine-groups/',
        params
      })
      const data = []

      for (const group of response.data) {
        data.push(serializers.deserializePostEngineGroup(group))
      }

      return data
    },
    retrieve: async id => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/craigslist/postengine-groups/${id}/`
      })
      return serializers.deserializePostEngineGroup(response.data)
    },
    create: async data => {
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: '/craigslist/postengine-groups/',
        data: serializers.serializePostEngineGroup(data)
      })
      return serializers.deserializePostEngineGroup(response.data)
    },
    update: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'put',
        url: `/craigslist/postengine-groups/${id}/`,
        data: serializers.serializePostEngineGroup(data)
      })
      return serializers.deserializePostEngineGroup(response.data)
    },
    partialUpdate: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'patch',
        url: `/craigslist/postengine-groups/${id}/`,
        data: serializers.serializePostEngineGroup(data)
      })
      return serializers.deserializePostEngineGroup(response.data)
    },
    delete: async (id, params) => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'delete',
        url: `/craigslist/postengine-groups/${id}/`,
        params
      })
      return response
    }
  },
  group_memberships: {
    list: async groupId => {
      if (!groupId) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/craigslist/postengine-groups/${groupId}/memberships/`
      })
      return response.data
    },
    retrieve: async (groupId, membershipId) => {
      if (!groupId || !membershipId) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/craigslist/postengine-groups/${groupId}/memberships/${membershipId}/`
      })
      return response.data
    },
    create: async (groupId, data) => {
      if (!groupId) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: `/craigslist/postengine-groups/${groupId}/memberships/`,
        data: data
      })
      return response.data
    },
    partialUpdate: async (groupId, membershipId, data) => {
      if (!groupId || !membershipId) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'patch',
        url: `/craigslist/postengine-groups/${groupId}/memberships/${membershipId}/`,
        data: data
      })
      return response.data
    },
    delete: async (groupId, membershipId) => {
      if (!groupId) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'delete',
        url: `/craigslist/postengine-groups/${groupId}/memberships/${membershipId}/`
      })
      return response
    }
  },
  syndication_photos: {
    list: async (ownerId, ownerType) => {
      if (!ownerId) {
        throw new MissingIdError()
      }
      if (!resourceOwnerTypes.includes(ownerType)) {
        throw new InvalidOwnerTypeError()
      }
      const response = await request({
        method: 'get',
        url: `/craigslist/${ownerType}/${ownerId}/syndication-photos/`
      })
      const data = []
      for (const photo of response.data) {
        data.push(serializers.deserializeSyndicationPhoto(photo))
      }

      return data
    },
    create: async (ownerId, ownerType, data) => {
      if (!ownerId) {
        throw new MissingIdError()
      }
      if (!resourceOwnerTypes.includes(ownerType)) {
        throw new InvalidOwnerTypeError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: `/craigslist/${ownerType}/${ownerId}/syndication-photos/`,
        data: data
      })
      return serializers.deserializeSyndicationPhoto(response.data)
    },
    delete: async (ownerId, ownerType, photoId) => {
      if (!ownerId || !photoId) {
        throw new MissingIdError()
      }
      if (!resourceOwnerTypes.includes(ownerType)) {
        throw new InvalidOwnerTypeError()
      }
      const response = await request({
        method: 'delete',
        url: `/craigslist/${ownerType}/${ownerId}/syndication-photos/${photoId}/`
      })
      return response
    }
  },
  properties: {
    list: async params => {
      const url = '/craigslist/properties/'
      const pageSize = 100
      const response = await paginatedRequest(url, pageSize, params)
      const data = []

      for (const property of response.data) {
        if (property.property.name === 'Unknown Property') {
          continue
        }
        data.push(serializers.deserializeCraigslistProperty(property))
      }

      return data
    },
    listPage: async params => {
      const response = await request({
        method: 'get',
        url: '/craigslist/properties/',
        params
      })
      response.data.data = []

      for (const property of response.data.results) {
        if (property.property.name === 'Unknown Property') {
          response.data.count -= 1
          continue
        }
        response.data.data.push(serializers.deserializeCraigslistProperty(property))
      }

      return response.data
    },
    retrieve: async (id, params) => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/craigslist/properties/${id}/`,
        params
      })
      return serializers.deserializeCraigslistProperty(response.data)
    },
    create: async data => {
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: '/craigslist/properties/',
        data: serializers.serializeCraigslistProperty(data)
      })
      return serializers.deserializeCraigslistProperty(response.data)
    },
    update: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'put',
        url: `/craigslist/properties/${id}/`,
        data: serializers.serializeCraigslistProperty(data)
      })
      return serializers.deserializeCraigslistProperty(response.data)
    },
    partialUpdate: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'patch',
        url: `/craigslist/properties/${id}/`,
        data: serializers.serializeCraigslistProperty(data)
      })
      return serializers.deserializeCraigslistProperty(response.data)
    },
    delete: async id => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'delete',
        url: `/craigslist/properties/${id}/`
      })
      return response
    }
  },
  accounts: {
    list: async params => {
      const response = await request({
        method: 'get',
        url: '/craigslist/accounts/',
        params
      })
      const data = []

      for (const account of response.data) {
        data.push(serializers.deserializeCraigslistAccount(account))
      }

      return data
    },
    listPage: async params => {
      params.paged = true
      const response = await request({
        method: 'get',
        url: '/craigslist/accounts/',
        params
      })
      response.data.data = []

      for (const company of response.data.results) {
        response.data.data.push(serializers.deserializeCraigslistAccount(company))
      }

      return response.data
    },
    retrieve: async email => {
      if (!email) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/craigslist/accounts/${email}/`
      })
      return serializers.deserializeCraigslistAccount(response.data)
    },
    create: async data => {
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: '/craigslist/accounts/',
        data: serializers.serializeCraigslistAccount(data)
      })
      return response.data
    },
    update: async (email, data) => {
      if (!email) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'put',
        url: `/craigslist/accounts/${email}/`,
        data: serializers.serializeCraigslistAccount(data)
      })
      return response.data
    },
    partialUpdate: async (email, data) => {
      if (!email) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'patch',
        url: `/craigslist/accounts/${email}/`,
        data: serializers.serializeCraigslistAccount(data)
      })
      return response.data
    },
    delete: async email => {
      if (!email) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'delete',
        url: `/craigslist/accounts/${email}/`
      })
      return response.data
    }
  },
  regions: {
    list: () => {
      return request({
        method: 'get',
        url: '/craigslist/regions/'
      })
    }
  },
  mappings: {
    list: async params => {
      const response = await request({
        method: 'get',
        url: '/craigslist/mappings/',
        params
      })
      return serializers.deserializeMappingList(response.data)
    }
  },
  feature_invites: {
    create: async (data) => {
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: '/craigslist/feature-invite/',
        data: serializers.serializeFeatureInvite(data)
      })
      return response.data
    }
  },
  scheduled_posts: {
    list: async (params = {}) => {
      const response = await request({
        method: 'get',
        url: '/craigslist/scheduled-posts/',
        params
      })
      return response.data
    }
  },
  service_queue: {
    list: async params => {
      const url = '/craigslist/service-queue/'
      const pageSize = 100
      const response = await paginatedRequest(url, pageSize, params)
      return response.data
    }
  },
  missed_tasks: {
    list: async params => {
      const url = '/craigslist/service-queue/failures/'
      const pageSize = 100
      const response = await paginatedRequest(url, pageSize, params)
      return response.data
    }
  },
  notes: {
    partialUpdate: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'patch',
        url: `/craigslist/service-queue/notes/${id}/`,
        data: serializers.serializeNotes(data)
      })
      return response.data
    }
  },
  verification_numbers: {
    list: async params => {
      const url = '/craigslist/verification-numbers/'
      const pageSize = 100
      const response = await paginatedRequest(url, pageSize, params)
      return response.data
    },
    retrieve: async (id, params) => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/craigslist/verification-numbers/${id}/`,
        params
      })
      return response.data
    },
    create: async data => {
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: '/craigslist/verification-numbers/',
        data: data
      })
      return response.data
    },
    update: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'put',
        url: `/craigslist/verification-numbers/${id}/`,
        data: data
      })
      return response.data
    },
    partialUpdate: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'patch',
        url: `/craigslist/verification-numbers/${id}/`,
        data: data
      })
      return response.data
    },
    delete: async id => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'delete',
        url: `/craigslist/verification-numbers/${id}/`
      })
      return response
    }
  },
  verification_numbers_code: {
    create: async data => {
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: '/craigslist/verification-numbers/received/',
        data: data
      })
      return response.data
    }
  }
}

export default CraigslistAPI
